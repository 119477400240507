// src/components/MostViewedTheses.js
import React from 'react';

const MostViewedTheses = ({ data }) => {
    return (
        <div className="mt-4">
            <h2>Most Viewed Theses</h2>
            <ul className="list-group">
                {data.map((thesis, index) => (
                    <li key={index} className="list-group-item">
                        {thesis.title} - {thesis.views} views
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MostViewedTheses;
