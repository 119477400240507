import React, { useState } from 'react';
import CommentBox from './CommentBox';
import CommentList from './CommentList';
import Notification from './Notification';
import FeedbackSummary from './FeedbackSummary';

const PeerReviewPage = () => {
  // Dummy data for comments
  const [comments, setComments] = useState([
    { id: 1, text: 'Great thesis! I really liked your approach.', author: 'Alice' },
    { id: 2, text: 'This section needs more detail. Consider adding references.', author: 'Bob' },
    { id: 3, text: 'Interesting perspective. Would love to discuss this further.', author: 'Charlie' },
  ]);

  // Dummy data for notifications
  const [notifications, setNotifications] = useState([
    { message: 'New comment added by Alice.' },
    { message: 'Bob replied to your comment.' },
    { message: 'Charlie liked your comment.' },
  ]);

  // Dummy data for feedback summary
  const feedbackSummary = {
    total: comments.length,
    positive: comments.filter(comment => comment.text.includes('great') || comment.text.includes('interesting')).length,
    negative: comments.filter(comment => comment.text.includes('needs more')).length,
  };

  return (
    <div className='container'>
      <h1>Peer Review Section</h1>
      <Notification notifications={notifications} />
      <CommentBox />
      <CommentList comments={comments} />
      <FeedbackSummary feedback={feedbackSummary} />
    </div>
  );
};

export default PeerReviewPage;
