// src/components/Charts.js
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, Filler, PointElement } from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    Filler,
    PointElement // Register the PointElement here
);

const Charts = () => {
    // Sample data for the bar chart
    const barData = {
        labels: ['Thesis A', 'Thesis B', 'Thesis C'],
        datasets: [
            {
                label: 'Views',
                data: [1200, 950, 780],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Sample data for the area chart
    const areaData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Downloads',
                data: [400, 450, 500, 550, 600, 650],
                fill: true, // This creates the area chart effect
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Statistics Dashboard',
            },
        },
    };

    return (
        <div className="mt-4">
            <h2>Visualizations</h2>
            <div style={{ height: '400px', marginBottom: '40px' }}>
                <h3>Bar Chart - Thesis Views</h3>
                <Bar data={barData} options={options} />
            </div>
            <div style={{ height: '400px' }}>
                <h3>Area Chart - Thesis Downloads</h3>
                <Line data={areaData} options={options} />
            </div>
        </div>
    );
};

export default Charts;
