// TrackSubmission.js
import React, { useState } from 'react';

const TrackSubmission = () => {
    const [submissionID, setSubmissionID] = useState('');
    const [trackingResult, setTrackingResult] = useState('');

    const handleTrackSubmission = (e) => {
        e.preventDefault();
        // Mock tracking result
        const result = `Your submission with ID ${submissionID} is currently being reviewed.`;
        setTrackingResult(result);
    };

    return (
        <div className="container mt-5">
            <h2>Track Your Submission</h2>
            <form onSubmit={handleTrackSubmission}>
                <div className="form-group">
                    <label htmlFor="submissionID">Enter Submission ID</label>
                    <input type="text" className="form-control" id="submissionID" value={submissionID} onChange={(e) => setSubmissionID(e.target.value)} required />
                </div>
                <button type="submit" className="btn btn-primary">Track Submission</button>
            </form>
            {trackingResult && (
                <div className="mt-4">
                    <div className="alert alert-info">{trackingResult}</div>
                </div>
            )}
        </div>
    );
};

export default TrackSubmission;
