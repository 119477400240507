// Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log('Menu toggled!'); // For debugging
    setMenuOpen(prev => !prev);
  };

  return (
    <header className="header">
      <div className="logo">
        <h1> Grad Thesis</h1>
      </div>
      <nav className="nav">
        <ul className={menuOpen ? 'active' : ''}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/statistics">Statistics</Link></li>
          <li><Link to="/peer-review">Peer Review</Link></li>
          <li><Link to="/submission-form">Submit Thesis</Link></li>
          <li><Link to="/about">About Us</Link></li>
          
          <li><Link to="/search">Search Thesis</Link></li>
          <li><Link to="/contact">Contact Us</Link></li>
        </ul>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </header>
  );
};

export default Header;
