import React from 'react';

const AboutUs = () => {
    const teamMembers = [
        {
            name: 'Rithika',
            role: 'Project Manager',
            bio: 'John has over 10 years of experience in project management.',
            image: 'https://via.placeholder.com/150',
        },
        {
            name: 'Jyothsna',
            role: 'Lead Developer',
            bio: 'Jane is a passionate developer with a love for coding.',
            image: 'https://via.placeholder.com/150',
        },
        {
            name: 'Tejas',
            role: 'UI/UX Designer',
            bio: 'Alice specializes in creating user-friendly interfaces.',
            image: 'https://via.placeholder.com/150',
        },
    ];

    return (
        <div className="">
            <section className="hero">
                <div className="hero-content text-center">
                    <h1>About Us</h1>
                    <p>Discover our mission, our team, and our journey towards academic excellence.</p>
                </div>
            </section>

            <section className="mission-statement mb-4 container">
                <h2>Our Mission</h2>
                <p>To provide a platform for sharing and developing academic theses that contribute to global knowledge.</p>
            </section>

            <section className="team mb-4 container">
                <h2>Meet the Team</h2>
                <div className="row">
                    {teamMembers.map((member, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="card mb-4 shadow-sm">
                                {/* <img src={member.image} className="card-img-top" alt={`${member.name}'s profile`} /> */}
                                <div className="card-body">
                                    <h5 className="card-title">{member.name}</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">{member.role}</h6>
                                    <p className="card-text">{member.bio}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className="history mb-4 container">
                <h2>History and Development</h2>
                <p>The repository was created in 2024 to facilitate the sharing of academic research...</p>
            </section>

            <section className="contact-info mb-4 container">
                <h2>Contact Information</h2>
                <p>If you have any questions, please reach out to us at: rithika@gmail.com</p>
            </section>
        </div>
    );
};

export default AboutUs;
