// src/components/MostDownloadedTheses.js
import React from 'react';

const MostDownloadedTheses = ({ data }) => {
    return (
        <div className="mt-4">
            <h2>Most Downloaded Theses</h2>
            <ul className="list-group">
                {data.map((thesis, index) => (
                    <li key={index} className="list-group-item">
                        {thesis.title} - {thesis.downloads} downloads
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MostDownloadedTheses;
