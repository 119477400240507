import React from 'react';

const CommentList = ({ comments }) => {
  return (
    <div>
      <h2>Comments</h2>
      <ul>
        {comments.map((comment, index) => (
          <li key={index}>
            <p>{comment.text}</p>
            <small>{comment.author}</small>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CommentList;
