// ThesisSubmissionForm.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Change this line

const ThesisSubmissionForm = () => {
    const [thesisTitle, setThesisTitle] = useState('');
    const [authorName, setAuthorName] = useState('');
    const [email, setEmail] = useState('');
    const [uploadFile, setUploadFile] = useState(null);
    const navigate = useNavigate(); // Change this line

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle file upload and submission logic here
        // Example: Upload file and get submission ID from server

        // Simulate a successful submission with a timeout
        setTimeout(() => {
            // Use navigate instead of history.push
            navigate('/submission-confirmation', { state: { submissionID: '123456', authorName } });
        }, 1000);
    };

    return (
        <div className="container mt-5">
            <h2>Thesis Submission Form</h2>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-group">
                    <label htmlFor="thesisTitle">Thesis Title</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="thesisTitle" 
                        value={thesisTitle} 
                        onChange={(e) => setThesisTitle(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="authorName">Author Name</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="authorName" 
                        value={authorName} 
                        onChange={(e) => setAuthorName(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input 
                        type="email" 
                        className="form-control" 
                        id="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="uploadFile">Upload Thesis Document (PDF)</label>
                    <input 
                        type="file" 
                        className="form-control-file" 
                        id="uploadFile" 
                        accept=".pdf" 
                        onChange={(e) => setUploadFile(e.target.files[0])} 
                        required 
                    />
                </div>
                <button type="submit" className="btn btn-primary">Submit Thesis</button>
            </form>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link className="navbar-brand" to="/">Thesis Submission</Link>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/template-downloads">Template Downloads</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/submission-form">Submit Your Thesis</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/submission-confirmation">Submission Confirmation</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/track-submission">Track Submission</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </div>
    );
};

export default ThesisSubmissionForm;
