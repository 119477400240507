// TemplateDownloads.js
import React from 'react';
import { Link } from 'react-router-dom';

const TemplateDownloads = () => {
    return (
        <div className="container mt-5">
            <h2>Template Downloads</h2>
            <p>Download the necessary templates for your thesis:</p>
            <ul>
                <li><a href="templates/thesis_template.docx" className="btn btn-secondary">Thesis Template (Word)</a></li>
                <li><a href="templates/thesis_template.tex" className="btn btn-secondary">Thesis Template (LaTeX)</a></li>
            </ul>
            <Link to="/submission-guidelines" className="btn btn-info">Back to Submission Guidelines</Link>
        </div>
    );
};

export default TemplateDownloads;
