// src/components/Contact.js
import React, { useState } from 'react';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Form submitted');
        setFormData({ name: '', email: '', subject: '', message: '' });
    };

    const faqs = [
        {
            question: 'How can i view Thesis',
            answer: 'You can view thesis by search thesis page.',
        },
        {
            question: 'How to Upload Thesis',
            answer: 'You can upload thesis by visiting submit thesis page.',
        },
        {
            question: 'How do I contact customer support?',
            answer: 'You can contact customer support through this contact form or by emailing rithika@gmail.com.',
        },
    ];

    return (
        <div className="">
            <section className="hero">
                <div className="hero-content text-center">
                    <h1>Contact Us</h1>
                </div>
            </section>
            <div className='container'>
            <h1>Contact Us</h1>
            <div className='row'>
            <form onSubmit={handleSubmit} className="contact-form mb-4 col-6 card">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Phone</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Send Message</button>
            </form>
            <section className="contact-info col-6">
                <h2>Contact Information</h2>
                <p>Email: rithika@gmail.com</p>
                
            </section>
            </div>
            <section className="faq mb-4">
                <h2>Frequently Asked Questions (FAQ)</h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <h5>{faq.question}</h5>
                        <p>{faq.answer}</p>
                    </div>
                ))}
            </section>

            </div>
        </div>
    );
};

export default Contact;
