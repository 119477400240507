import React from 'react';

const FeedbackSummary = ({ feedback }) => {
  return (
    <div>
      <h2>Feedback Summary</h2>
      <p>Total Comments: {feedback.total}</p>
      <p>Positive Feedback: {feedback.positive}</p>
      <p>Negative Feedback: {feedback.negative}</p>
    </div>
  );
};

export default FeedbackSummary;
