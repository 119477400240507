// src/components/StatisticsDashboard.js
import React from 'react';
import { thesisData } from './thesisData';
import MostViewedTheses from './MostViewedTheses';
import MostDownloadedTheses from './MostDownloadedTheses';
import Charts from './Charts';

const StatisticsDashboard = () => {
    return (
        <div className="container">
            <h1>Statistics Dashboard</h1>
            <MostViewedTheses data={thesisData.mostViewed} />
            <MostDownloadedTheses data={thesisData.mostDownloaded} />
            <Charts />
        </div>
    );
};

export default StatisticsDashboard;
