// SubmissionGuidelines.js
import React from 'react';
import { Link } from 'react-router-dom';

const SubmissionGuidelines = () => {
    return (
        <div className="container mt-5">
            <h2>Submission Guidelines</h2>
            <p>Please read the following guidelines carefully before submitting your thesis:</p>
            <ul>
                <li>Theses must be submitted in PDF format.</li>
                <li>Ensure that your thesis follows the provided template.</li>
                <li>Include all necessary sections, including an abstract, introduction, methodology, and conclusion.</li>
                <li>Check for plagiarism using appropriate software.</li>
            </ul>
            <Link to="/template-downloads" className="btn btn-info">Download Templates</Link>
            <Link to="/submission-form" className="btn btn-primary">Submit Your Thesis</Link>
        </div>
    );
};

export default SubmissionGuidelines;
