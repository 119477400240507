// src/data/thesisData.js
export const thesisData = {
    mostViewed: [
        { title: "Thesis A", views: 1200 },
        { title: "Thesis B", views: 950 },
        { title: "Thesis C", views: 780 },
    ],
    mostDownloaded: [
        { title: "Thesis D", downloads: 500 },
        { title: "Thesis E", downloads: 450 },
        { title: "Thesis F", downloads: 300 },
    ],
};
