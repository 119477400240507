// SubmissionConfirmation.js
import React from 'react';
import { useLocation } from 'react-router-dom';

const SubmissionConfirmation = () => {
    const location = useLocation();
    const { submissionID, authorName } = location.state || { submissionID: '', authorName: '' };

    return (
        <div className="container mt-5">
            <h2>Submission Confirmation</h2>
            <p>Thank you for submitting your thesis, {authorName}!</p>
            <p>Your submission ID is: <strong>{submissionID}</strong></p>
            <p>You can track the status of your submission using the ID above.</p>
        </div>
    );
};

export default SubmissionConfirmation;
