import React, { useState } from 'react';

const CommentBox = () => {
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Commented');
    setComment('');
  };

  return (
    <form onSubmit={handleSubmit} className='form-group'>
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Add your comment..."
        required
        className='form-control'
      />
      <br></br>
      <button type="submit" className='btn'>Submit Comment</button>
    </form>
  );
};

export default CommentBox;
