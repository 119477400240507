import React from 'react';

const Home = () => {
    return (
        <div className="home-container">
            <section className="hero">
                <div className="hero-content">
                    <h1>Welcome to the Digital Thesis Repository</h1>
                    <p>Explore a centralized platform for accessing and submitting academic theses and dissertations.</p>
                    <button className="cta-button">Get Started</button>
                </div>
                
            </section>

            <section className="about">
                <div className="about-image">
                    <img src="./about.jpg" alt="About Us" />
                </div>
                <div className="about-content">
                    <h2>About the Repository</h2>
                    <p>
                        Our repository aims to facilitate the sharing and discovery of academic work. 
                        We provide an easy-to-use interface for authors to submit their theses and for users 
                        to search and access these works effectively.
                    </p>
                </div>
            </section>

            <section className="featured-theses">
                <h2>Featured Theses</h2>
                <div className="theses-grid">
                    <div className="thesis-item">
                        <h3>WDM THesis</h3>
                        <p>Author: John Doe</p>
                        <p>Abstract: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <a href="/thesis/1" className="thesis-link">View Details</a>
                    </div>
                    <div className="thesis-item">
                        <h3>Thesis on Machine Learning</h3>
                        <p>Author: Jane Smith</p>
                        <p>Abstract: Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a href="/thesis/2" className="thesis-link">View Details</a>
                    </div>
                    <div className="thesis-item">
                        <h3>Thesis on Data Mining</h3>
                        <p>Author: Alice Johnson</p>
                        <p>Abstract: Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</p>
                        <a href="/thesis/3" className="thesis-link">View Details</a>
                    </div>
                    <div className="thesis-item">
                        <h3>Thesis on Data Mining</h3>
                        <p>Author: Alice Johnson</p>
                        <p>Abstract: Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</p>
                        <a href="/thesis/3" className="thesis-link">View Details</a>
                    </div>
                </div>
            </section>
            <div className='footer'>
                <div className='row'>
                    <div className='container text-center'>
                        2024 Grad Thesis
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home
;
