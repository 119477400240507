// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Search from './Search';
import SubmissionGuidelines from './SubmissionGuidelines';
import TemplateDownloads from './TemplateDownloads';
import ThesisSubmissionForm from './ThesisSubmissionForm';
import TrackSubmission from './TrackSubmission';
import SubmissionConfirmation from './SubmissionConfirmation';
import StatisticsDashboard from './StatisticsDashboard';
import AboutUs from './AboutUs';
import Contact from './Contact';
import PeerReviewPage from './PeerReviewPage';

const App = () => {
  return (
    <div>
     
    <Router>
    <Header />
        <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/search' element={<Search/>}/>
        <Route path="/" exact element={<SubmissionGuidelines/>} />
                <Route path="/template-downloads" element={<TemplateDownloads/>} />
                <Route path="/submission-form" element={<ThesisSubmissionForm/>} />
                <Route path="/submission-confirmation" element={<SubmissionConfirmation/>} />
                <Route path="/track-submission" element={<TrackSubmission/>} />
                <Route path='/statistics' element={<StatisticsDashboard/>}/>
                <Route path='/about' element={<AboutUs/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/peer-review' element={<PeerReviewPage/>}/>
        </Routes>
      
    </Router>
    </div>
  );
};

export default App;
