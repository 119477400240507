import React, { useState } from 'react';
import './Search.css';

const Search = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [topicFilter, setTopicFilter] = useState('');
    const [authorFilter, setAuthorFilter] = useState('');
    const [yearFilter, setYearFilter] = useState('');
    const [keywordFilter, setKeywordFilter] = useState('');
    const [results, setResults] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const resultsPerPage = 5;

    const handleSearch = () => {
        const dummyData = [
            { id: 1, title: 'Exploring Machine Learning Techniques', author: 'John Doe', year: '2022', keywords: 'Machine Learning, AI' },
            { id: 2, title: 'A Study on Web Development Trends', author: 'Jane Smith', year: '2023', keywords: 'Web Development, JavaScript' },
            { id: 3, title: 'Blockchain Technology and Its Applications', author: 'Alice Johnson', year: '2021', keywords: 'Blockchain, Cryptocurrency' },
            { id: 4, title: 'Understanding Data Structures', author: 'Michael Brown', year: '2022', keywords: 'Data Structures, Algorithms' },
            { id: 5, title: 'An Analysis of Quantum Computing', author: 'Emily Davis', year: '2023', keywords: 'Quantum Computing, Physics' },
            { id: 6, title: 'Artificial Intelligence in Healthcare', author: 'Sarah Wilson', year: '2020', keywords: 'AI, Healthcare' },
            { id: 7, title: 'The Future of Cybersecurity', author: 'David Lee', year: '2021', keywords: 'Cybersecurity, IT' },
            { id: 8, title: 'Mobile App Development with React Native', author: 'Jessica Miller', year: '2022', keywords: 'Mobile Development, React Native' },
            { id: 9, title: 'The Role of Big Data in Business', author: 'Robert Garcia', year: '2023', keywords: 'Big Data, Business' },
            { id: 10, title: 'Sustainable Energy Solutions', author: 'Linda Martinez', year: '2022', keywords: 'Sustainability, Energy' },
            { id: 11, title: 'Introduction to Cloud Computing', author: 'William Hernandez', year: '2021', keywords: 'Cloud Computing, IT' },
            { id: 12, title: 'E-commerce Strategies in the Modern Age', author: 'Patricia Thompson', year: '2023', keywords: 'E-commerce, Marketing' },
            { id: 13, title: 'Robotics and Automation in Manufacturing', author: 'James Anderson', year: '2020', keywords: 'Robotics, Automation' },
            { id: 14, title: 'Analyzing User Experience in Mobile Apps', author: 'Barbara Robinson', year: '2022', keywords: 'User Experience, Mobile Apps' },
            { id: 15, title: 'Financial Technology Innovations', author: 'Charles Clark', year: '2021', keywords: 'FinTech, Innovation' },
            { id: 16, title: 'Social Media Impact on Society', author: 'Daniel Lewis', year: '2023', keywords: 'Social Media, Society' },
            { id: 17, title: 'Advancements in 3D Printing', author: 'Jennifer Lee', year: '2022', keywords: '3D Printing, Technology' },
            { id: 18, title: 'Effective Project Management Techniques', author: 'Thomas Young', year: '2021', keywords: 'Project Management, Techniques' },
            { id: 19, title: 'Exploring Renewable Energy Sources', author: 'Nancy King', year: '2022', keywords: 'Renewable Energy, Environment' },
            { id: 20, title: 'The Impact of Virtual Reality on Education', author: 'Kevin Wright', year: '2023', keywords: 'Virtual Reality, Education' },
        ];

        const filteredResults = dummyData.filter(item => {
            return (
                (topicFilter ? item.keywords.toLowerCase().includes(topicFilter.toLowerCase()) : true) &&
                (authorFilter ? item.author.toLowerCase().includes(authorFilter.toLowerCase()) : true) &&
                (yearFilter ? item.year === yearFilter : true) &&
                (keywordFilter ? item.keywords.toLowerCase().includes(keywordFilter.toLowerCase()) : true)
            );
        });

        setResults(filteredResults);
        setCurrentPage(1); 
    };

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginateResults = () => {
        const startIndex = (currentPage - 1) * resultsPerPage;
        const endIndex = startIndex + resultsPerPage;
        return results.slice(startIndex, endIndex);
    };

    return (
        <div className="search-container">
            <h1>Advanced Thesis Search</h1>
            <div className="search-filters">
                <input
                    type="text"
                    placeholder="Search by Topic"
                    value={topicFilter}
                    onChange={(e) => setTopicFilter(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Search by Author"
                    value={authorFilter}
                    onChange={(e) => setAuthorFilter(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Search by Year"
                    value={yearFilter}
                    onChange={(e) => setYearFilter(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Search by Keywords"
                    value={keywordFilter}
                    onChange={(e) => setKeywordFilter(e.target.value)}
                />
                <button onClick={handleSearch} className="search-button">Search</button>
            </div>

            <table className="results-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Year</th>
                        <th>Keywords</th>
                    </tr>
                </thead>
                <tbody>
                    {paginateResults().map(item => (
                        <tr key={item.id}>
                            <td>{item.title}</td>
                            <td>{item.author}</td>
                            <td>{item.year}</td>
                            <td>{item.keywords}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="pagination">
                {[...Array(Math.ceil(results.length / resultsPerPage)).keys()].map(number => (
                    <button
                        key={number + 1}
                        onClick={() => handlePagination(number + 1)}
                        className={currentPage === number + 1 ? 'active' : ''}
                    >
                        {number + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Search;
